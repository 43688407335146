<template>
	<div style="height: 100%;overflow: auto;">
		<el-form ref="form" :model="detail" label-width="80px">
			<el-row>
				<el-col :span="18" v-if="detail.type == 2">
					<el-form-item label-width="120px" label="支付宝账号"><el-input disabled v-model="detail.alipayAccount"></el-input></el-form-item>
				</el-col>
				<el-col :span="18" v-if="detail.type == 2 || detail.type == 3">
					<el-form-item label-width="120px" label="支付宝收款人"><el-input disabled v-model="detail.alipayName"></el-input></el-form-item>
				</el-col>
				<el-col :span="18" v-if="detail.type == 3">
					<el-form-item label-width="120px" label="支付宝二维码">
						<el-image style="width: 100px; height: 100px" z-index="60000" :src="detail.alipayCode" :preview-src-list="[detail.alipayCode]"></el-image>
					</el-form-item>
				</el-col>
				<el-col :span="18" v-if="detail.type == 2 || detail.type == 3">
					<el-form-item label-width="120px" label="备注"><el-input disabled v-model="detail.note"></el-input></el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="18" v-if="detail.type == 1">
					<el-form-item label-width="120px" label="微信二维码">
						<el-image style="width: 100px; height: 100px" z-index="60000" :src="detail.wxCode" :preview-src-list="[detail.wxCode]"></el-image>
					</el-form-item>
				</el-col>
				<el-col :span="18" v-if="detail.type == 1">
					<el-form-item label-width="120px" label="备注"><el-input disabled v-model="detail.note"></el-input></el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="18" v-if="detail.type == 4">
					<el-form-item label-width="150px" label="银行开户行"><el-input disabled v-model="detail.bankName"></el-input></el-form-item>
				</el-col>
				<el-col :span="18" v-if="detail.type == 4">
					<el-form-item label-width="150px" label="银行账号"><el-input disabled v-model="detail.bankNumber"></el-input></el-form-item>
				</el-col>
				<el-col :span="18" v-if="detail.type == 4">
					<el-form-item label-width="150px" label="银行收款人真实姓名"><el-input disabled v-model="detail.bankUserName"></el-input></el-form-item>
				</el-col>
				<el-col :span="18" v-if="detail.type == 4">
					<el-form-item label-width="150px" label="银行收款人联系电话"><el-input disabled v-model="detail.bankUserPhone"></el-input></el-form-item>
				</el-col>
				<el-col :span="18" v-if="detail.type == 4">
					<el-form-item label-width="150px" label="备注"><el-input disabled v-model="detail.note"></el-input></el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
export default {
	props: {
		selectdata: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			detail: {}
		};
	},
	created() {
		this.getDetail();
	},
	methods: {
		async getDetail() {
			const res = await this.$axios('POST', `/sys/wx/collectionAccountNumber/details`, { accountId: this.selectdata.collectionId }, true);
			this.detail = res;
		}
	}
};
</script>

<style scoped>
	
</style>
